import Layout from "../components/layouts/layout/layout"
import FeatureContent from "../components/layouts/feature-content/feature-content"
import { errorPage404 } from "../page-content/shared/content-errors"

const NotFoundPage = () => (
  <Layout>
    <FeatureContent {...errorPage404.featureContent} />
  </Layout>
)

export default NotFoundPage

export const Head = () => {
  return (
    <>
      <title>{errorPage404.seo.title}</title>
      {errorPage404.seo.description && (
        <meta name="description" content={errorPage404.seo.description} />
      )}
      <meta property="og:title" content={errorPage404.seo.title} />
      {errorPage404.seo.description && (
        <meta
          property="og:description"
          content={errorPage404.seo.description}
        />
      )}
      <meta property="og:type" content="website" />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content="ORY" />
      <meta property="twitter:title" content={errorPage404.seo.title} />
      {errorPage404.seo.description && (
        <meta
          property="twitter:description"
          content={errorPage404.seo.description}
        />
      )}
    </>
  )
}
